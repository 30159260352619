<template>
  <VContainer
    fluid
    class="pa-0 text-center"
  >
    <VRow
      justify="center"
    >
      <VCol
        v-if="showContentSearchButton"
        cols="auto"
      >
        <TTBtn
          fab
          depressed
          color="white"
          elevation="1"
          large
          data-test="aside-right-content-search"
          class="mb-2 v-btn--aside"
          @click="handleClickShowSearchBar"
        >
          <VIcon
            color="tt-black"
            size="19"
          >
            fal fa-search
          </VIcon>
        </TTBtn>
        <div class="tt-text-caption tt-black--text text--lighten-2">
          Поиск по контенту
        </div>
      </VCol>
      <VCol cols="auto">
        <TTBtn
          fab
          depressed
          color="white"
          elevation="1"
          large
          data-test="aside-right-task-add"
          class="mb-2 v-btn--aside"
          @click="$root.$emit('showTaskContentForm')"
        >
          <VIcon
            color="tt-black"
            size="19"
          >
            fal fa-plus
          </VIcon>
        </TTBtn>
        <div class="tt-text-caption tt-black--text text--lighten-2">
          Добавить<br>
          элемент
        </div>
      </VCol>
    </VRow>
  </VContainer>
</template>
<script>
export default {
  name: 'AppContentListAsideRight',
  props: {
    showContentSearchButton: {
      type: Boolean,
      default: true,
    },
  },
  methods: {
    handleClickShowSearchBar() {
      this.$root.$emit('onClickShowSearchBar');
    },
  },
};
</script>

<style scoped>
/* stylelint-ignore no-empty-source */
</style>
